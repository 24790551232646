@keyframes mdFadeIn {
    0% {
        transform: scale(1.5);
        opacity: 0;
    }
    17% {
        opacity: 1;
    }
    100% {
        transform: scale(1.25);
    }
}

@keyframes mdFadeOut {
    0% {
        transform: scale(1.25);
        opacity: 1;
    }
    20% {
        opacity: 0;
    }
    100% {
        transform: scale(1);
    }
}

.images-animated-container{
    position: relative;
    overflow: hidden;
}

.images-animated img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.image-animated-previous{
    animation-name: mdFadeOut;
}

.image-animated-current{
    animation-name: mdFadeIn;
}

.image-animated-previous,
.image-animated-current{
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.image-animated-other{
    opacity: 0;
}