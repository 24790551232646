/*footer*/

.main-footer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.main-footer-absolute{
    position: absolute;
    bottom: 0;
    width: 100%;
    /*height: 100%;*/
}

.main-footer-relative {
    /*background-color: rgba(255, 255, 255, 0.7);*/

    /*background-color: #adabac;*/
    background-color: rgba(32, 33, 33, 0.2);

    /*background-color: rgba(0, 0, 0, 0.7);*/
    /*color: whitesmoke;*/
    padding: 15px 0 0 0;
    position: relative;
    width: 100%;
    height: 100%;
}

.footer-icons {
    text-align: center;
    margin-bottom: -0.5vw;
}

.footer-icons :nth-child(1) {
    background-image: url("https://loftic.ge/frontendMedia/icon1.png");
}

.footer-icons :nth-child(2) {
    background-image: url("https://loftic.ge/frontendMedia/icon2.png");
}

.footer-icons :nth-child(3) {
    background-image: url("https://loftic.ge/frontendMedia/icon3.png");
}

.footer-icon {
    cursor: pointer;
    width: 27px;
    height: 27px;
}

.footer-icons > div {
    width: 25px;
    height: 28px;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    padding: 0 30px;
    background-repeat: no-repeat;
}

.footer-copyright {
    padding: 0 0 5px 10px;
}

.top-ge-container{
    position: absolute;
    z-index: 20;
    width: 100px;
    height: 40px;
    bottom: 0;
    right: 0;
    padding: 0 10px 5px 0;
}