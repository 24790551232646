@import "~bpg-extrasquare-mtavruli";
@import "~bpg-nino-elite-caps";

/*  ქართულენოვანი ვერსიისთვის ფონტის შერჩევა  */
* {
    font-family: "BPG Nino Elite Exp", sans-serif;
}

/*საერთო სტილები responsive-სთვის*/
.inline-responsive {
}

.width-100-28-29 {
}

.width-100-38-39 {
}

.width-100-48-49 {
}

.width-100-58-59 {
}

.width-100-68-69 {
}

/*  საერთო, ზოგადი სტილები  */
body {
    margin: 0;
    padding: 0;
    background-color: white;
}

/*frontend styles*/

.content-wrapper {
    max-width: 1200px;
    padding: 0 10px;
    margin: 0 auto;
    position: relative;
}

.simple-text {
    font-family: "BPG Nino Elite Caps", sans-serif;
    font-weight: 700;
}

.text-color,
.text-color::placeholder {
    color: rgb(235, 235, 235) !important;
}

.sub-text {
    /*font-family: "BPG LE Studio 02 Caps", sans-serif;*/
    font-family: "BPG Nino Elite Caps", sans-serif;
    font-weight: 700;
}

.header-text {
    /*font-family: ALKDots, sans-serif !important;*/
    /*font-family: "ALK Rex Bold", sans-serif !important;*/
    font-family: "BPG ExtraSquare Mtavruli", sans-serif;
    font-weight: 700;
    /*color: white;*/
    color: rgb(235, 235, 235);
    text-shadow: 0 0 5px black;
    padding-left: 27px;;
    position: absolute;
    width: 95% !important;
}

.inline-responsive {
}

/*  მობილურებისთვის:  */

/*.image-container {*/
/*    width: 50%;*/
/*}*/

/*საერთო სტილები responsive-სთვის*/

.simple-text {
    font-size: 12px;
}

.sub-text {
    font-size: 13px;
}

.header-text {
    font-size: 22px;
    margin-top: -30px;
}

.inline-responsive {
    display: block;
}

.width-100-28-29 {
    width: 100% !important;
}

.width-100-38-39 {
    width: 100%;
}

.width-100-48-49 {
    width: 100%;
}

.width-100-58-59 {
    width: 100%;
}

.width-100-68-69 {
    width: 100%;
}

/*  ტაბლეტებისთვის:  */
@media only screen and (min-width: 646px) {

    .simple-text {
        font-size: 14px;
    }

    .sub-text {
        font-size: 13px;
    }

    .header-text {
        font-size: 40px;
        margin-top: -46px;
    }


    /*საერთო სტილები responsive-სთვის*/
    .inline-responsive {
        display: inline-block;
        vertical-align: top;
    }

    .width-100-28-29 {
        width: 28% !important;
    }

    .width-100-38-39 {
        width: 38%;
    }

    .width-100-48-49 {
        width: 48%;
    }

    .width-100-58-59 {
        width: 58%;
    }

    .width-100-68-69 {
        width: 68%;
    }
}

/*  დესკტოპისთვის:  */
@media only screen and (min-width: 900px) {

    .simple-text {
        font-size: 14px;
    }

    .sub-text {
        font-size: 13px;
    }

    .header-text {
        font-size: 50px;
        margin-top: -55px;
    }

    /*საერთო სტილები responsive-სთვის*/
    .inline-responsive {
        display: inline-block;
        vertical-align: top;
    }

    .width-100-28-29 {
        width: 29% !important;
    }

    .width-100-38-39 {
        width: 39%;
    }

    .width-100-48-49 {
        width: 49%;
    }

    .width-100-58-59 {
        width: 59%;
    }

    .width-100-68-69 {
        width: 69%;
    }
}