.main {
    background-color: #202121;
    min-height: 100vh;
    position: relative;
    width: 100%;
    height: 100%;
}

.main-background-image {
    background-image: url("https://loftic.ge/frontendMedia/main-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
}
