/*header*/

.header {
    /*display: flex;*/
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    max-width: 100vw;
}

.header-menu {
    margin-left: 20.8% !important;
    width: 75% !important;
}

.header-menu > * {

}

.header-menu > *:hover {
    /*background-color: transparent !important;*/
}

.ant-menu-submenu-popup {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.ant-menu-item:hover {
    /*background-color: transparent !important;*/
}

header * {
    background-color: transparent !important;
}

.header-main-logo-container {
    width: 20vw;
    /*display: inline-block;*/
    position: absolute;
    /*vertical-align: middle;*/
    margin-left: 7px;
}

.header-main-logo {
    background-image: url("https://loftic.ge/frontendMedia/lofticLogoSmall.png");
    /*width: 70px;*/
    width: 75px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    /*margin: 10px auto;*/
    margin: 8px auto;
    /*position: absolute;*/
}

.ant-menu {
    background: none !important;
    color: white !important;
}

.ant-menu-horizontal {
    border-bottom: none !important;
    display: inline-block;
    width: 80vw;
    vertical-align: middle;
    text-align: center;
}

.ant-menu-header > :nth-child(2) {
    background-color: transparent !important;
}

.header-menu-item-language {
    float: right;
    background-color: transparent !important;
    border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
    padding-right: 0 !important;
    padding-left: 10px !important;
    border-left: 1px solid rgba(255, 255, 255, 0.3) !important;
    border-radius: 5px;
}

.header-menu-item-language.ant-menu-item-selected {
    background-color: transparent !important;
}

.header-menu-item-language:hover {
    background-color: transparent !important;
}

.header-translator > * {
    background-color: white !important;
    color: white !important;
    padding: 0 20px !important;
}

html {
    /*--antd-wave-shadow-color: #1890ff;*/
    --antd-wave-shadow-color: black !important;
}

::selection {
    color: #fff;
    /*background: #1890ff;*/
    background: black !important;
}

.header-translator > *:hover,
.ant-menu-item-active {
    background-color: white !important;
    color: black !important;
}

.ant-menu-submenu-title:hover {
    border-bottom: transparent !important;
    color: white !important;
}

.ant-menu-submenu-selected, .ant-menu-submenu-active,
.ant-menu-submenu, .ant-menu-submenu-horizontal, .ant-menu-overflowed-submenu {
    border-bottom: transparent !important;
    color: white !important;
}

.ant-menu-item-selected {
    /*color: #5a5a5a !important;*/
    color: black !important;
    background-color: white !important;
    border: none !important;
}

.ant-menu-item {
    position: relative;
    top: 0 !important;
    padding: 3px 20px !important;
    display: inline-block;
    vertical-align: middle !important;
    border: none !important;
    text-align: center;
}

.ant-menu-item-active {
    background-color: whitesmoke !important;
    color: black !important;
}

.antd-button-style {
    display: inline-block !important;
    border: none !important;
    box-shadow: none !important;
    /*padding: 0 10px !important;*/
    border-radius: 0 !important;
    height: auto !important;
    background-color: transparent !important;
}

.antd-button-style:nth-child(1) {
    /*padding: 10px 10px 10px 0 !important;*/
}

.antd-button-style:nth-child(2) {
    /*border-left: 2px solid #5a5a5a !important;*/
    border-left: 1px solid white !important;
    /*padding: 10px 0 10px 10px !important;*/
}

/*ant menu submenu modification*/

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    padding: 0 10vw !important;
    display: block;
    margin: 0 !important;
}

.ant-menu-submenu > .ant-menu {
    padding: 5px 0;
}

.ant-menu-submenu-selected, .ant-menu-submenu-active, .ant-menu-submenu, .ant-menu-submenu-horizontal, .ant-menu-overflowed-submenu {
    border-bottom: transparent !important;
    color: white !important;
    right: 10px;
    left: auto !important;
    top: 55px;
}

/*somehow needed to add these styles to ant-menu items, after update*/

/*.ant-menu-horizontal > */
.ant-menu-item > a {
    display: block;
    color: whitesmoke !important;
}

/*.ant-menu-horizontal > */
.ant-menu-item > a:hover {
    display: block;
    color: rgba(0, 0, 0, 0.65) !important;
}

/*.ant-menu-horizontal > */
.ant-menu-item-selected > a {
    display: block;
    color: rgba(0, 0, 0, 0.65) !important;
}

.simple-text,
.sub-text,
.sub-text a,
.sub-text button{
    font-family: BPG Nino Elite Caps,sans-serif;
    font-weight: 700;
}

.header-translator span {
    font-weight: 500 !important;
}
