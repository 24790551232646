/*contact*/
img {
    vertical-align: top;
}

input {
    background: none !important;
    background-color: transparent !important;
    border-bottom: 1px solid #737373 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.react-phone-number-input__input:-webkit-autofill {
    box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0) inset !important;
}

.contact {
    background-color: #202121;
}

.contact-image {
    /*background-image: url(https://loftic.ge/images/5db354f85f2352.65801916.jpg);*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    background-position: center;
    width: 100%;
    /*height: 60vh;*/
    height: 50vh;
}

.contact-inputs-container {
    /*min-height: 400px;*/
    /* border: 1px solid black; */
    border-radius: 5px;
    margin-top: -27vh;
    /*margin-bottom: 10vh;*/
    /* margin-bottom: 3vh; */
    margin-left: auto;
    margin-right: auto;
    width: 90vw;
    max-width: 700px;
    /* background-color: rgba(235, 235, 235, 0.85); */
    /* background-color: rgba(0, 0, 0, 0.7); */
    /*padding: 10px 15px 11vh 15px;*/
    padding: 10px 15px 5vh 15px;
    position: relative;
}

.contact-form{
    /* padding: 10vh 5% 5% 5%; */
}

.contact-input::before{
    content: "*";
    color: red;
    font-weight: bold;
}

.contact-input:focus{
    outline: none;
}

.contact-input {
    border: none;
    margin-left: 35px;
    margin-bottom: 20px;
    width: Calc( 100% - 35px );
    background: none;
    /* border-bottom: 1px solid rgb(235, 235, 235) !important; */
    border-bottom: 1px solid #737373 !important;
}

.contact-number-input{
    margin-bottom: 20px;
}
.contact-number-input :nth-child(1) > :nth-child(2),
.contact-number-input :nth-child(1) > :nth-child(2)::placeholder{
    /* border-bottom: 1px solid rgb(235, 235, 235) !important; */
    color: rgba(235, 235, 235, 0.85);
}
.contact-submit-container{
    text-align: right;
}
.contact-submit{
    width: 100%;
    max-width: 250px;
    padding: 8px 5px 5px 5px;
    border-radius: 4px;
    border: 1px solid rgb(235, 235, 235);
    cursor: pointer;
    margin-top: 30px;
}

.contact-submit:hover {
    /* background-color: rgba(255, 255, 255, 0.5) !important; */
    background-color: rgba(0, 0, 0, 0.5) !important;
}
.contact-submit:focus{
    outline: none;
}

.success-waiting,
.success-message
{
    color: red;
}

.contact-info-container{
    width: 100%;
    padding-bottom: 15vh;
}

.contact-info{
    padding: 10px 15px;
    border-radius: 5px;
    /*border: 1px solid black;*/
    width: 90vw;
    max-width: 700px;
    /* background-color: rgba(0, 0, 0, 0.7); */
    margin: 0 auto;

    /*display: flex;*/
    flex-flow: row nowrap;
    justify-content: space-around;
}

.contact-info table{
    max-width: 50%;
    font-weight: 600;
    margin: 0 auto;
    padding-left: 7vw;
}

.row-1{
    padding-right: 5vw;
    text-align: right;
}