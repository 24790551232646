/*projects*/

.projects{
    background-color: #202121;
}

.projects-header-text-container {
    color: white;
    /*margin: 160px 10% 50px 10%;*/
    margin: 130px 10% 15px 10%;
    width: 80%;
    text-align: center;
    border-bottom: 1px solid #979797;
}

.projects-header-text {
    margin-top: -46px !important;
    padding: 0 !important;
    width: 80% !important;
    color: white;
}

.projects-image {
    background-image: url("https://loftic.ge/images/5db354f85f2352.65801916.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 60vh;
}

.projects-flex-container {
    display: flex;
}

.projects-flex-container :nth-child(2) {
    width: 100%;
}

.projects-text-container {
    min-height: 400px;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: -27vh;
    margin-bottom: 10vh;
    margin-left: 3%;
    width: 90vw;
    max-width: 700px;
    background-color: rgba(235, 235, 235, 0.85);
    padding: 10px 15px 20vh 15px;
    position: relative;

    flex: 0 0 90vw;
}

.projects-text {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.projects-text p {
}

.projects-image-galleries-container {
    padding: 100px 10% 10% 10%;
    text-align: center;
}

.projects-image-gallery {
    /*width: 100%;*/
    /*padding-top: 100%;*/
    position: relative;
    display: inline-block;
    vertical-align: top;
    /*margin: 1.5%;*/
    margin: 0 3% 3% 0;

    width: 100%;
    padding-top: 100%;
}

.projects-image-gallery-with-text-container {
    position: relative;
    display: inline-block;
    vertical-align: top;
    /*margin: 1.5%;*/
    margin: 0 3% 3% 0;
}

.image-gallery-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.image-gallery,
.image-gallery-content,
.image-gallery-slide-wrapper,
.image-gallery-swipe,
.image-gallery-slides,
.image-gallery-image,
.image-gallery-slide {
    height: 100% !important;
}

/*image gallery classes overwright*/

.image-gallery {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before,
.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
    color: rgba(255, 255, 255, 0.6) !important;
    line-height: 0.2 !important;
    text-shadow: 0 2px 2px rgba(100, 100, 100, 0.6) !important;
}

.image-gallery-fullscreen-button:hover::before,
.image-gallery-play-button:hover::before,
.image-gallery-left-nav:hover::before,
.image-gallery-right-nav:hover::before {
    color: rgba(255, 255, 255, 1) !important;
    text-shadow: 0 2px 2px rgba(100, 100, 100, 1);
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    top: 50%;
    transform: translateY(-50%);
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
    /*bottom: 15px;*/
    /*top: -60%;*/
}

/*end of overwrighting*/

/*  მობილურისთვის  */

/*.projects-image-gallery {*/
/*    width: 100%;*/
/*    padding-top: 100%;*/
/*}*/
.projects-image-gallery-with-text-container {
    width: 100%;
}

.project-description-container {
    font-weight: 700;
}

.project-description-container-in-box {
    width: 100%;
    height: fit-content;
    z-index: 2;
    position: absolute;
    bottom: 0;
    /*background-color: rgba(209, 209, 209, 0.7);*/

    /*background-color: rgba(89, 89, 89, 0.7);*/
    background-color: rgba(255, 255, 255, 0.7);
    color: rgba(0, 0, 0, 0.8);

    /*font-weight: 600;*/
    /*border-top: 1px solid #545454;*/
    padding: 5px;
    /*color: #eaeaea;*/
}

.project-description-container-in-box > p {
    margin-bottom: 3px;
}

.projects-image-gallery-with-text-container {

}

/*projects' csses after react-image-gallery update*/

.image-gallery-slide > div {
    height: 100%;
    width: 100%;
}

.image-gallery-slides img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
    cursor: pointer;
    z-index: 1001 !important;
}

.image-gallery-fullscreen-button, .image-gallery-play-button {
    top: 0 !important;
    bottom: auto !important;
    padding: 20px 14px !important;
}

.image-gallery-icon:focus{
    outline: none !important;
}



.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 70px !important;
}

.image-gallery-left-nav, .image-gallery-right-nav {
    padding: 50px 0 20px 0 !important;
    top: 43% !important;
}


.image-gallery-fullscreen-button,
.image-gallery-play-button,
.image-gallery-left-nav,
.image-gallery-right-nav{
    color: #cccccc !important;
    opacity: 0.7;
}

.image-gallery-fullscreen-button:hover,
.image-gallery-play-button:hover,
.image-gallery-left-nav:hover,
.image-gallery-right-nav:hover {
    color: whitesmoke !important;
    opacity: 1;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
     max-height: 100vh !important;
}

.image-gallery.fullscreen-modal{
    z-index: 1001 !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    /*padding: 5vh !important;*/
}

.image-gallery-slides img {
    /*object-fit: contain !important;*/
}

.image-gallery.fullscreen-modal {
    /*padding: 5vh;*/
}

/*.image-gallery-fullscreen-button.active{*/
/*    background-color: red !important;*/
/*}*/

.image-gallery-fullscreen-button{
    padding-right: 0 !important;
}

.image-gallery-right-nav{
    padding-right: 21px !important;
}
.image-gallery-left-nav{
    padding-left: 21px !important;
}

/*  ტაბლეტისთვის   */
@media only screen and (min-width: 646px) {
    /*.projects-image-gallery {*/
    /*    width: 47%;*/
    /*    padding-top: 47%;*/
    /*}*/
    .projects-image-gallery-with-text-container {
        width: 47%;
    }
}

/*  დესკტოპისთვის:  */
@media only screen and (min-width: 900px) {
    /*.projects-image-gallery {*/
    /*    width: 30%;*/
    /*    padding-top: 30%;*/
    /*}*/
    .projects-image-gallery-with-text-container {
        width: 30%;
    }
}